import { parseURL } from '@archipro-website/top-navigation';

export const getAdvertUrlParams = (
    url: URL
): {
    setId: number;
    forceAdvertCreativeId: number | null;
    resetAdvertCache: string;
} => {
    let setId = 0;
    let forceAdvertCreativeId: number | null = null;
    let resetAdvertCache = '';
    if (url.searchParams?.has('setId')) {
        const rawSetId = Number(url.searchParams?.get('setId'));

        if (!isNaN(rawSetId) && rawSetId > 0) {
            setId = rawSetId;
        }
    }

    if (url.searchParams?.has('resetAdvertCache')) {
        resetAdvertCache = url.searchParams?.get('resetAdvertCache') ?? '';
    }

    if (url.searchParams?.has('forceAdvertCreativeId')) {
        const rawForceAdvertCreativeId = Number(
            url.searchParams?.get('forceAdvertCreativeId')
        );

        if (!isNaN(rawForceAdvertCreativeId) && rawForceAdvertCreativeId > 0) {
            forceAdvertCreativeId = rawForceAdvertCreativeId;
        }
    }

    return {
        setId,
        forceAdvertCreativeId,
        resetAdvertCache,
    };
};

export const extractAdvertRequestUrlContext = (
    url: URL,
    pathContextParams: string[]
): Record<string, string> => {
    pathContextParams.push('utm_campaign'); // Utm campaign ALWAYS matters

    const pathContext: Record<string, string> = {};
    if (!pathContextParams.length) {
        return pathContext;
    }

    const { params } = parseURL(url.href);
    if (params && pathContextParams.length) {
        Object.keys(params).forEach((key) => {
            const value = params[key];
            if (pathContextParams.includes(key)) {
                pathContext[key] = String(value) ?? '';
            }
        });
    }

    return pathContext;
};

export const keepArchiProDevAbsoluteUrlLocal = (url: string): string => {
    if (typeof window === 'undefined') {
        return url;
    }

    const liveAbsoluteHosts = ['archipro.co.nz', 'archipro.com.au'];
    const currentHost = window.location.host;

    // If the current host is a live host, do nothing
    if (liveAbsoluteHosts.includes(currentHost)) {
        return url;
    }

    const parser = document.createElement('a');
    parser.href = url;

    if (parser.host.includes('archipro')) {
        parser.host = window.location.host;

        return parser.href;
    }

    if (!parser.host) {
        return url;
    }

    return url;
};
