export const TRACK_DOWNLOAD_CLASSNAME = "track-download";
export const TRACK_REFERRAL_CLASSNAME = "track-referral";

export const DOWNLOAD_ATTR_PROFESSIONAL_TITLE = 'data-professional';
export const DOWNLOAD_ATTR_FILE_TITLE = 'data-file-title';

export const REFERRAL_ATTR_PROFESSIONAL_TITLE = 'data-professional';
export const REFERRAL_ATTR_ITEM_ID = 'data-item-id';

export default {
    TRACK_DOWNLOAD_CLASSNAME,
    TRACK_REFERRAL_CLASSNAME,
    DOWNLOAD_ATTR_PROFESSIONAL_TITLE,
    DOWNLOAD_ATTR_FILE_TITLE,
    REFERRAL_ATTR_PROFESSIONAL_TITLE,
    REFERRAL_ATTR_ITEM_ID
}